import singleSpaReact from 'single-spa-react';
import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';
import user from '@adobe/exc-app/user';
import page from '@adobe/exc-app/page';
import { type IWorkspace } from '@wf-mfe-maestro/api';

import { getIsInGenStudio } from './shellHelpers';
import { log } from './logger';
import { getRecordTypePagePath } from './getRecordTypePagePath';
import { DEFAULT_GENSTUDIO_VIEW_ID, GenStudioRecordTypeAliases } from './constants';

/**
 * Tests if url is a short alias url like '/products' or '/campaigns', and if so,
 * extracts alias from the URL and returns url to the actual record type page.
 * @param workspace
 */
export const useAliasRedirectUrl = (workspace?: IWorkspace) => {
  const parts = window.location.pathname.split('/').filter(Boolean);
  if (parts.length === 1 && workspace) {
    const recordTypeAlias = parts[0];
    const actualRecordType = workspace.recordTypes.find(
      (recordTypeItem) => recordTypeItem.alias === recordTypeAlias
    );
    if (actualRecordType) {
      return getRecordTypePagePath(workspace.id, actualRecordType.id, DEFAULT_GENSTUDIO_VIEW_ID);
    }
  }
};

export const getMaestroRoutes = async (): Promise<RouteObject[]> => {
  const routeElements: RouteObject[] = [];

  if (getIsInGenStudio()) {
    let workspace: IWorkspace | undefined;
    try {
      const { api } = await System.import<typeof import('@wf-mfe-maestro/api')>(
        '@wf-mfe-maestro/api'
      );
      workspace = await api.workspace.getGenStudioWorkspace();
    } catch (error) {
      log.error(error, { errorMessage: 'Failed to load GenStudio workspace' });
    }

    GenStudioRecordTypeAliases.forEach((recordTypeAlias) => {
      routeElements.push(
        {
          path: `/${recordTypeAlias}/:workspaceId/:recordTypeId/:viewId/view`,
          lazy: async () => {
            const { viewRecordTypeRouteProperties } = await System.import('@wf-mfe-maestro/view');
            return viewRecordTypeRouteProperties;
          },
        },
        {
          path: `/${recordTypeAlias}/:workspaceId/:recordTypeId/:recordId/record/:pageId?`,
          lazy: async () => {
            const { recordDetailsRouteProperties } = await System.import(
              '@wf-mfe-maestro/details-page-ui'
            );
            return recordDetailsRouteProperties;
          },
        },
        {
          path: `/${recordTypeAlias}`,
          Component: () => {
            const navigate = useNavigate();
            const redirectUrl = useAliasRedirectUrl(workspace);
            useLayoutEffect(() => {
              if (redirectUrl) {
                navigate(redirectUrl, { replace: true });
              }
            }, [navigate, redirectUrl]);
            return null;
          },
        }
      );
    });
  }

  return routeElements;
};

const getAliasFromUrl = (): string | undefined => {
  const parts = window.parent.location.href.split('/');
  return parts.find((part) => GenStudioRecordTypeAliases.has(part));
};

const {
  bootstrap,
  mount: mountSingleSpa,
  unmount: unmountSingleSpa,
} = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: async () => {
    const routes = await getMaestroRoutes();
    return () => <RouterProvider router={createBrowserRouter(routes)} />;
  },
  renderType: 'render',
  suppressComponentDidCatchWarning: true,
  domElementGetter: () => document.getElementById('page-content')!,
});

export const listener = () => {
  user.off('change:imsOrg', listener);
  const alias = getAliasFromUrl();
  if (alias) {
    page.shellRedirect(`/genstudio/${alias}`, { replace: true, discovery: true });
  }
};

export const mount = (...args: unknown[]) => {
  if (getIsInGenStudio()) {
    user.on('change:imsOrg', listener);
  }
  return mountSingleSpa(...args);
};

export const unmount = (...args: unknown[]) => {
  if (getIsInGenStudio()) {
    user.off('change:imsOrg', listener);
  }
  return unmountSingleSpa(...args);
};

export { bootstrap };
