import { getMaestroPath } from './getMaestroPath';
import { getIsInGenStudio } from './shellHelpers';
import { GenStudioRecordTypeAliases } from './constants';

export const getRecordPagePath = (
  workspaceId: string,
  recordTypeId: string,
  recordId: string,
  recordTypeAlias: string = '',
  pageId: string = ''
) => {
  if (getIsInGenStudio()) {
    let recordTypeAliasFinal = recordTypeAlias;
    if (!recordTypeAlias) {
      const parts = window.location.pathname.split('/').filter(Boolean);
      recordTypeAliasFinal = parts[0];
    }

    if (GenStudioRecordTypeAliases.has(recordTypeAliasFinal)) {
      return `/${recordTypeAliasFinal}/${workspaceId}/${recordTypeId}/${recordId}/record/${pageId}`;
    }
  }
  return getMaestroPath(`/${workspaceId}/${recordTypeId}/${recordId}/record/${pageId}`);
};
